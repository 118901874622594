<script setup lang="ts">
import type { MenuItem } from "~/models/Menu";

const { isDesktop } = useDevice();

const { menu, partner } = defineProps<{
  menu: MenuItem[];
  partner?: string;
}>();
</script>
<template>
  <header class="header-nav">
    <div class="header-nav-wrapper">
      <NuxtLink to="/">
        <img src="/img/logo_extracadabra_full.svg" alt="logo extracadabra" />
      </NuxtLink>
      <template v-if="menu">
        <navDesktop v-if="isDesktop" :menu="menu" />
        <navMobile v-else :menu="menu" />
      </template>
      <template v-if="partner">
        <img class="header-nav-partner-logo" :src="partner" alt="logo partenaire" />
      </template>
    </div>
  </header>
</template>
<style lang="scss" scoped>
.header-nav {
  background-color: $primary-color;
  height: $menu-height;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  @include header-shadow();
  @include mq(desktop) {
    padding: 0 0 0 1rem;
  }
  @include mq(wide) {
    padding: 0 3rem;
  }
  &-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    max-width: 1130px;
    margin: 0 auto;
    justify-content: inherit;
    @include mq(desktop) {
      max-width: 1300px;
    }
  }
  &-partner-logo {
    position: absolute;
    right: 0;
    @include mq(desktop) {
      position: inherit;
    }
  }
  img {
    min-width: 104px;
    max-height: 50px;
  }
}
</style>
